import axios from "axios";
import { isIEBrowser } from "./Utils";
import CODES from "./StatusCodes";

const METHOD = {
    GET: "get",
    POST: "post",
    PUT: "put",
    DELETE: "delete",
};

const BASEURL = process.env.REACT_APP_API_URL;

class Api {
    constructor() {
        this.baseURL = BASEURL;
    }

    post(url, data){
        return new Promise((resolve, reject) => {
            this.api(METHOD.POST, url, data)
            .then((response) => {
                resolve(response);
            })
            .catch((error) => {
                console.log(error);
                reject(error);
            });
        });
    }
    api(method, url, data) {
        return new Promise((resolve, reject) => {
            let axiosConfig = {};
            axiosConfig.method = method;

            axiosConfig.url = this.baseURL + url;
            if (data) {
                if (data.params) axiosConfig.params = data.params;

                if (data.data) axiosConfig.data = data.data;
            }

            // if (isIEBrowser()) {
            //     if (!axiosConfig.params) axiosConfig.params = {};
            //     axiosConfig.params.time = new Date().getTime();
            // }

            axios(axiosConfig)
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    console.log("ERROR", error);
                });
        });
    }


}

export default Api;
import styled from "styled-components";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import { responsive } from "Styles/Constants";

export const WrapperIcon = styled(ChevronLeftIcon)`
    color: #c47c15;
    align-self:center;
`;

export const ComingSoonWrapper = styled.div `
    display:flex;
    justify-content:center;
    width:100%;
    align-items:center;
    margin:auto;
    margin-bottom: 25px;
    max-width: 1200px;
    .content-container{
        width: 50vw;
        ${responsive.TABLET`
            width:80vw;
        `}
        
    }
    .container{
        box-shadow: 0px 10px 0 0 rgba(0, 0, 0, 0.09);
        border: solid 10px #c57d16;
        padding-top: 1rem;
        padding-bottom: 1rem;
        display: flex;
        .heading{
            font-size: 28px;
            font-weight: bold;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            text-align: center;
            margin-bottom: 20px;
            color: #c47c15;
            .btn{
                float: left;
            }.top-heading{
                margin-left: -8%;
            }
        }
        .image-container{
            padding-bottom: 2rem;
            width: fit-content;
            margin: auto;
            .book-image{
                width: 140px;
                height: 200px;
                margin: 20px 0px 0px 20px;
                object-fit: contain;
            }
            .empty-image{
                width: 140px;
                height: 200px;
                margin: -110px 0px 0px 75px;
                border: solid 3px #9d5425;
                background-color: #ecc6ad;
                .text{
                    font-size: 18px;
                    margin: 135px 0px -130px 0px;
                    font-weight: normal;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: normal;
                    letter-spacing: normal;
                    text-align: center;
                    color: #9d5425;
                }
            }
        }
    }
`;
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { HomeWrapper } from './home.style';
import Content from '../../Components/DynamicContainer/DynamicContainer';
import { headerText } from '../../actions/actions'

function LandingPage(){
    
const dispatch = useDispatch()

    useEffect(() => {
        dispatch(headerText('HOME'))
    },[dispatch])

    return(
        <HomeWrapper>
        <Content/>
        </HomeWrapper>
    );

    
}

export default LandingPage;
import { useFormik } from "formik";
import Button from '@mui/material/Button';
import { useNavigate } from "react-router-dom";
import React, { useRef,useEffect } from "react";
import { useState } from "react";
import { useDispatch } from 'react-redux';
import { headerText } from '../../actions/actions';
import {
  MainBox,
  WrapperButton,
  WrapperEmail,
  WrapperIcon,
  WrapperMessage,
  WrapperName,
} from "./ContactUs.style";
import { Snackbar } from "@mui/material";
import { IconButton } from "@mui/material";
import { Close } from "@mui/icons-material";
import * as Yup from "yup";
import emailjs from "emailjs-com";
import Api from "Helpers/ApiHandler";
import { SUBMIT_QUERY } from "Helpers/Paths";





function ContactUs() {

    let navigate = useNavigate();
    async function handleSubmit() {
        navigate(-1);
    }
    const dispatch = useDispatch()
    useEffect(() => {
        dispatch(headerText('CONTACT'))
    },[dispatch])

  const formRef = useRef(null);
  const [open, setOpen] = useState(false);
  const formik = useFormik({
    initialValues: {
      name: "",
      email: "",
      message: "",
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .required("Please Enter Your Email")
        .email("Please Enter a Valid Email Address"),
    }),
    onSubmit: (values, { resetForm }) => {
     
      sendMail(values);
      // sendEmail(values);
      handleClick();
      resetForm();
    },
  });

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  const handleClick = () => {
    setOpen(true);
  };

  function sendEmail() {
   
    //SERVICE_ID         TEMPLATEID         TEMPLATE_PARAMS   PUBLIC_KEY
    emailjs
      .sendForm(
        "service_aso4hb7",
        "template_0dsrfuj",
        formRef.current,
        "pQeGTIwkZwI72vtTb"
      )
      .then(
        (result) => {
          console.log(result);
        },
        (error) => {
          console.log(error.text);
        }
      );
  }

  async function sendMail(values){

    try{
      // let data = new FormData();
      // data.append("name", values.name)
      // data.append("email", values.email)
      // data.append("message", values.message)

      // let response = await new Api().post(SUBMIT_QUERY, { data })
      console.log("inside try");
      const response =  await new Api().post(SUBMIT_QUERY, {
        data:{
          name:values.name,
          email:values.email,
          message:values.message,
        },
        
      });
      console.log("line 109");

    }catch(error){
      console.log(error);
    }

  }

  return (
    <>
      <MainBox>
        <div className="box">
          <div className="parent-head">
            <Button onClick={handleSubmit}><WrapperIcon /></Button>
            <div className="parent-div">
              <div className="contact-us">
                <span style={{fontWeight:"bold"}}>Contact Us</span>
              </div>
            </div>
          </div>
          <form className="form" ref={formRef} onSubmit={formik.handleSubmit}>
            <Snackbar
              open={open}
              autoHideDuration={6000}
              onClose={handleClose}
              message="Data Submitted Successfully"
              anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
              action={
                <IconButton
                  size="small"
                  aria-label="close"
                  color="inherit"
                  onClick={handleClose}
                >
                  <Close fontSize="small" />
                </IconButton>
              }
            ></Snackbar>
            <WrapperName
              color="primary"
              autoFocus="autoFocus"
            //   id="standard-basic"
              placeholder="Name:"
              variant="standard"
              required
              name="name"
              key="name"
              value={formik.values.name}
              onChange={formik.handleChange}
            />
            <WrapperEmail
              color="primary"            
              placeholder="Email:"
              name="email"
              key="name"
            //   id="standard-basic"
              variant="standard"
              value={formik.values.email}
              onChange={formik.handleChange}
              helperText={
                formik?.touched?.email && formik.errors.email
                  ? formik.errors.email
                  : ""
              }
            />
           
            <WrapperMessage
              placeholder="Type your message here…"
              name="message"
              required
              id="outlined-multiline-flexible"
              multiline
              maxRows={2}
              value={formik.values.message}
              onChange={formik.handleChange}
              key="message"
            />
            

            <WrapperButton
              // className="button-text"
              variant="text"
              type="submit"
              style={{fontWeight:"bold"}}
            >
              SUBMIT
            </WrapperButton>
          </form>
        </div>
      </MainBox>
    </>
  );
}

export default ContactUs;

import styledComponents from "styled-components";
import blueImg from "../../Assets/Images/MeetTheCharacters/blue-girl.png";
import brownImg from "../../Assets/Images/MeetTheCharacters/brown-girl.png";
import orangeImg from "../../Assets/Images/MeetTheCharacters/orange-girl.png";
import greenImg from "../../Assets/Images/MeetTheCharacters/green-girl.png";
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import styled from "@emotion/styled";
import { responsive } from "Styles/Constants";

export const MainBox = styledComponents.div`
    display:flex;
    justify-content:center;
    align-items:center;
    // padding-top:2px;

    .box{
        box-shadow: 0px 10px 0 0 rgba(0, 0, 0, 0.09);
        border: solid 10px #c57d16;
        // background-color: #c57d16;
        min-width:70vw;
        margin-bottom:37px;

        ${responsive.DISPLAY`
            min-width: 75vw ;
        `}

        ${responsive.DESKTOP`
            min-width: 78vw;
        `}

        @media screen and (max-width: 1312px){
            min-width:80vw;  
        }

        // @media screen and (max-width: 1300px){
        //     min-width:81vw;  
        // }

        ${responsive.LAPTOP`
            min-width:86vw
        `}

        @media screen and (max-width: 1187px){
            min-width:90vw;  
        }

        @media screen and (max-width: 1137px){
            min-width:94vw;  
        }

        @media screen and (max-width: 1090px){
            min-width:98vw;  
        }

        @media screen and (max-width: 1040px){
            min-width:98vw;  
        }
    }

    .parent-head{
        display:flex;
        padding-top:14px;
    }
    .parent-div{
        display:flex;
        justify-content:center;
        width:100%;
        align-items:center;


    }
   
    .meet-the-characters{
        display:flex;
        justify-content:center;
        font-family: InkFree;
        font-size: 27px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        text-align: left;
        color: #c47c15;
        width:100%;

        @media screen and (min-width: 1846px){
            font-size:33px;
        }
       
        @media screen and (min-width: 2560px){
            font-size:50px;
        }

       
    }

    .wrapper-content{
        display:flex;
        flex-direction:column;
        padding:2%;
    }

    .parent-first-div{
        display:flex;
        justify-content:center;
        padding-left:26%;

        
        ${responsive.TABLET`
            padding-left:31%;
        `}

        @media screen and (max-width: 834px){
            padding-left:49%
        }

        @media screen and (max-width: 768px){
            padding-left:40%;
        }
    }

    .blue-girl-image{
        // background-image:url(${blueImg});
        // width:8%;
        // height:182px;
        // background-size:contain;
        // background-repeat:no-repeat;
        height:150px;

        @media screen and (min-width: 1846px){
            height:187px;
        }

        @media screen and (min-width: 2560px){
            height:270px;
        }

        ${responsive.LAPTOP`
            height:127px;
        `}

        ${responsive.TABLET`
            height:120px;
        `}

        @media screen and (max-width: 796px){
            height:134px;
        }
    }

    .blue-text{
        font-family: InkFree;
        font-size: 14px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        text-align: left;
        color: #0071c0;
        padding-left:2%;
        padding-top:5%;

        @media screen and (min-width: 1846px){
            font-size:17px;
        }

        @media screen and (min-width: 2085px){
            font-size:20px;
        }

        @media screen and (min-width: 2560px){
            font-size:27px;
        }

       ${responsive.LAPTOP`
            font-size:11px;
        `}

        ${responsive.TABLET`
            font-size:12px;
        `}

        @media screen and (max-width: 796px){
            font-size:11px;
        }
    }

    .parent-second-div{
        display:flex;
        justify-content:flex-start;
        margin-top:-10%;
        margin-left:12%;
        gap:3%;

        @media screen and (min-width: 2000px){
            margin-left:15%;
        }   

        ${responsive.LAPTOP`
            margin-left:15%;
        `}

        @media screen and (max-width: 830px){
            margin-left:11%;  
        }

        @media screen and (max-width: 834px){
            margin-left:10%;  
        }

        @media screen and (max-width: 793px){
            margin-left:7%;
            margin-top:-11%;  
        }

        @media screen and (max-width: 768px){
            margin-left:5%;
            margin-top:-13%;  
        }

        @media screen and (max-width: 755px){
            margin-left:1%;
        }
    }

    .brown-girl-image{
        // background-image:url(${brownImg});
        // width:10%;
        // height:182px;
        // background-size:contain;
        // background-repeat:no-repeat;
        height:150px;
        padding-left:13px;
        
        @media screen and (min-width: 1846px){
            height:187px;
        }

        @media screen and (min-width: 2560px){
            height:270px;
        }

        ${responsive.LAPTOP`
            height:127px;
        `}

        ${responsive.TABLET`
            height:120px;
        `}

        @media screen and (max-width: 796px){
            height:134px;
        }
    }

    .brown-text{
        font-family: InkFree;
        font-size: 14px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        text-align: left;
        color: #261a09;
        padding-top:3%;

        @media screen and (min-width: 1846px){
            font-size:17px;
        }

        @media screen and (min-width: 2085px){
            font-size:20px;
        }

        @media screen and (min-width: 2560px){
            font-size:27px;
        }

        ${responsive.LAPTOP`
            font-size:11px;
        `}

        ${responsive.TABLET`
            font-size:10px;
        `}

        @media screen and (max-width: 796px){
            font-size:11px;
        }
    }

    .parent-third-div{
        display:flex;
        justify-content:flex-end;
        margin-top:-5%;
        margin-right:12%;

        ${responsive.TABLET`
            margin-top:-7%
            
        `}

        @media screen and (max-width: 844px){
            margin-top:-9%;  
        }

        @media screen and (max-width: 830px){
            margin-right:5%;
        }

        @media screen and (max-width: 834px){
            margin-right:-1%;  
        }

        @media screen and (max-width: 800px){
            margin-top:-11%;  
        }

        @media screen and (max-width: 796px){
            margin-top:-14%;  
        }

        @media screen and (max-width: 768px){
            // margin-right:4%;
            // margin-top:-15%;  
        }

        @media screen and (max-width: 755px){
            margin-right:1%;
            
        }
    }

    .green-girl-image{
        // content:url(${greenImg});
        // height:182px;
        // background-size:contain;
        // background-repeat:no-repeat;
        height:150px;

        @media screen and (min-width: 1846px){
            height:187px;
        }

        @media screen and (min-width: 2560px){
            height:270px;
        }

        ${responsive.LAPTOP`
            height:127px;
        `}

        ${responsive.TABLET`
            height:120px;
        `}

        @media screen and (max-width: 796px){
            height:134px;
        }
    }

    .green-text{
        font-family: InkFree;
        font-size: 14px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        text-align: left;
        color: #458f03;
        padding-top:5%;

        @media screen and (min-width: 1846px){
            font-size:17px;
        }

        @media screen and (min-width: 2085px){
            font-size:20px;
        }

        @media screen and (min-width: 2560px){
            font-size:27px;
        }

        ${responsive.LAPTOP`
            font-size:11px;
        `}

        ${responsive.TABLET`
            font-size:10px;
        `}

        @media screen and (max-width: 796px){
            font-size:11px;
        }
       
    }

    .parent-fourth-div{
        display:flex;
        justify-content:center;
        margin-top:-5%;
        // margin-left:-8%;

        @media screen and (min-width: 2560px){
            margin-top:-9%;
            margin-left:-22%;
        }

        @media screen and (min-width: 1846px){
            margin-left:-7%;
        }

        // ${responsive.DISPLAY`
        //     margin-left:-12%;
        // `}

        ${responsive.LAPTOP`
            margin-left:-7%;
            margin-top:-4%;
        `}

        @media screen and (max-width: 985px){
            margin-left:-14%;
            margin-top:-8%;  
        }

        ${responsive.TABLET`
            margin-top:-5%;
            margin-left:-10%;
        `}
        
        @media screen and (max-width: 844px){
            margin-left:-16%;
            margin-top:-8%;  
        }

        @media screen and (max-width: 830px){
            margin-left:-8%;  
        }

        @media screen and (max-width: 800px){
            margin-left:-14%;  
        }

        @media screen and (max-width: 793px){
            margin-left:-19%;  
        }

        @media screen and (max-width: 768px){
            margin-top:-6%;  
        }

        @media screen and (max-width: 755px){
            margin-top:-3%;  
        }
       
    }
    .orange-girl-image{
        // content:url(${orangeImg});
        // width:9%;
        // height:182px;
        // background-size:contain;
        // background-repeat:no-repeat;
        height:150px;

        @media screen and (min-width: 1846px){
            height:187px;
        }

        @media screen and (min-width: 2560px){
            height:270px;
        }

        ${responsive.LAPTOP`
            height:127px;
        `}

        ${responsive.TABLET`
            height:120px;
        `}

        @media screen and (max-width: 796px){
            height:134px;
        }

    }

    .orange-text{
        font-family: InkFree;
        font-size: 14px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        text-align: left;
        color: #f62;
        padding-top:5%;
        padding-left:2%;

        @media screen and (min-width: 1846px){
            font-size:17px;
        }

        @media screen and (min-width: 2085px){
            font-size:20px;
        }

        @media screen and (min-width: 2560px){
            font-size:27px;
        }
    
        ${responsive.LAPTOP`
            font-size:11px;
        `}

        ${responsive.TABLET`
        font-size:10px;
        `}

        @media screen and (max-width: 796px){
            font-size:11px;
        }
    }
`;

export const WrapperIcon = styled(ChevronLeftIcon)`
    color: #c47c15;
    align-self:center;
    margin-left: 25px;
`;

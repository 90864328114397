import { UtilityStyles } from "./Styles/Utils";
import Website from "./Pages/Website";
import { ThemeProvider as MuithemeProvider } from "@mui/material/styles";
import { createTheme } from "@mui/material/styles";
import  {THEME_SETTINGS} from "./themeSettings";  


const theme = createTheme(THEME_SETTINGS);

function App() {
  return (
    <div className="App">
      <MuithemeProvider theme={theme}>
      <Website />
      <UtilityStyles />
      </MuithemeProvider>
    </div>
  );
}

export default App;

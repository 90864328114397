const ACTIONS = {
    'HOME': 'HOME',
    'BIOGRAPHY': 'BIOGRAPHY',
    'COMING_SOON': 'COMING_SOON',
    'MEET_CHARACTERS': 'MEET_CHARACTERS',
    'CONTACT': 'CONTACT',
    'DESIGNS': 'DESIGNS'
}

const text = {
    home:  `<b> Hi, I’m City, City Garland.<br /> Let’s take a Sneak Peek into my<br />amazing fashion journey!</b>`,
    bio: '<b> Meet our Amazing Author and Illustrator!</b> ',
    soon: '<b> See our Book Series!</b>',
    meet: '<b> Learn more about your favorite Characters!</b>',
    contact: `<b> Any questions, comments and/or ideas<br /> you have please submit them here! </b>`,
    designs: `<b> Download your favorite designs<br/> and bring them to life with your<br/> favorite colors!</b>`
}

const INITIAL_STATE = {
    HEADER_TEXT : text.home
}

export default function TopBarHeaderText(state = INITIAL_STATE, action) {
    // When the app loads this would check for the state, which is undefined, so set it to null in the argument.
    switch (action.type) {
        case ACTIONS.HOME:
            return { HEADER_TEXT: text.home, displayLogo : true }
            
        case ACTIONS.BIOGRAPHY:
            return { HEADER_TEXT: text.bio }
            
        case ACTIONS.COMING_SOON:
            return { HEADER_TEXT: text.soon }
            
        case ACTIONS.MEET_CHARACTERS:
            return { HEADER_TEXT: text.meet }
        
        case ACTIONS.CONTACT:
            return { HEADER_TEXT: text.contact }
            
        case ACTIONS.DESIGNS:
            return { HEADER_TEXT: text.designs }
            
        default:
            return state
    }
}
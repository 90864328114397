import React from "react";
import { Link } from 'react-router-dom';
import { MainContainerWrapper } from "./DynamicContainer.style";
import BookImage from 'Assets/Images/LandingPage/bookpage.webp';
import ScooterGirl from 'Assets/Images/LandingPage/scooter-girl.webp';
import ThreeGirls from 'Assets/Images/LandingPage/three-girls.webp';
import CycleGirl from 'Assets/Images/LandingPage/cycle-girl.webp';
import MusicGirl from 'Assets/Images/LandingPage/music-girl.webp';
import DanceGirl from 'Assets/Images/LandingPage/dancing-girl.webp';

function Content() {
  return (
    <>
      <MainContainerWrapper>
        <div className="link-content">
          <ul className="primary-link">
            <li className="link"><Link className='link' to="/"></Link></li>
            <li className="link"><Link className="link" to="/biography">Bio</Link></li>
            <li className="link"><Link className="link" to="/coming-soon">Books</Link></li>
            <li className="link"><Link className="link" to="/meet-characters">Meet the Characters</Link></li>
            <li className="link"><Link className="link" to="/contact-us">Contact Us</Link></li>
            <li className="link"><Link className="link" to="/designs">Designs</Link></li>
          </ul>
        </div>
        <div className="image-container">
          <div className="first-block">
            <img class="book-image" src={BookImage} alt='book' />
            <div class="empty-image">
              <div className="text">Book II</div>
              <div className="text">Coming Soon</div>
            </div>
          </div>
          <div className="second-block">
            <img class="book-image" src={ScooterGirl} alt='book' />
            <img class="girl-image" src={ThreeGirls} alt='book' />
          </div>
          <div className="third-block">
            <img class="book-image" src={CycleGirl} alt='book' />
            <img class="girl-image" src={MusicGirl} alt='book' />
          </div>
          <div className="fourth-block">
          <img class="dance-image" src={DanceGirl} alt='book' />
          </div>
        </div>
      </MainContainerWrapper>
    </>
  );
}

export default Content;

import styled from "styled-components";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import { responsive } from "Styles/Constants.js";

export const WrapperIcon = styled(ChevronLeftIcon)`
  color: #c47c15;
  align-self: center;
`;

export const BiographyWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
  width: 90%;
  margin-bottom: 40px;
  max-width: 1200px;
  .container {
    box-shadow: 0px 10px 0 0 rgba(0, 0, 0, 0.09);
    border: solid 10px #c57d16;
    padding-bottom: 1rem;
    padding-top: 1rem;
    display: flex;
    .heading {
      font-size: 28px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      text-align: center;
      margin-bottom: 20px;
      color: #c47c15;
      .btn {
        float: left;
      }
      .top-heading {
        margin-left: -8%;
      }
    }
    .mini-container {
      display: flex;
      flex-direction: row;
      justify-content: center;
      gap: 5%;
      ${responsive.TABLET`
            padding-left:5rem;
            padding-right:5rem;     
            font-size:18px;
            display:flex;
            flex-direction:column;
            gap: 20px;
        `}
      .first-container {
        width: 45%;
        padding: 1rem;
        box-shadow: 0px 10px 0 0 rgba(0, 0, 0, 0.09);
        border: solid 10px #c47c15;
        ${responsive.TABLET`
                    width:100%
            
                `}
        .heading {
          font-family: InkFree;
          font-size: 28px;
          font-weight: bold;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: -1.92px;
          text-align: center;
          color: #261a09;
          padding-left: 31%;
        }
        .text {
          font-size: 18px;
          font-weight: bold;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: 1.2px;
          text-align: left;
          .addressing {
            font-size: 18px;
            letter-spacing: 1.8px;
          }
          .content {
            .addressing {
              font-size: 18px;
              letter-spacing: 1.8px;
            }
            .author-name {
              font-size: 24px;
              letter-spacing: 1.44px;
            }
          }
        }
        .parent-head{
            display: flex;
            justify-content: space-around;
            align-items: center;
        }
        .author-img {
          height: 65px;
          width: 68px;
        }

        .description {
          font-size: 18px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: 1.2px;
          text-align: left;
          .text {
            .author-name {
              font-size: 24px;
              letter-spacing: 1.44px;
            }
          }
        }
        .social-link {
          display: flex;
          flex-direction: column;
          gap: 10px;
          .link-container {
            font-weight: bold;
            .link {
              margin-left: 10px;
              text-decoration: underline;
              color: #c57d16;
            }
          }
        }
      }
      .second-container{
        width: 45%;
        padding: 1rem;
        box-shadow: 0px 10px 0 0 rgba(0, 0, 0, 0.09);
        border: solid 10px #c47c15;
        ${responsive.TABLET`
                    width:100%
            
                `}
        .heading {
          font-family: InkFree;
          font-size: 28px;
          font-weight: bold;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: -1.92px;
          text-align: center;
          color: #261a09;
          padding-top: 21px;
        }
        
        .parent-content{
            padding-top:4px;
        }
        .text {
          font-size: 18px;
          font-weight: bold;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: 1.2px;
          text-align: left;
          .addressing {
            font-size: 18px;
            letter-spacing: 1.8px;
          }
          .content {
            .addressing {
              font-size: 18px;
              letter-spacing: 1.8px;
            }
            .author-name {
              font-size: 24px;
              letter-spacing: 1.44px;
            }
          }
        }
        
        .description {
          font-size: 18px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: 1.2px;
          text-align: left;
          .text {
            .author-name {
              font-size: 24px;
              letter-spacing: 1.44px;
            }
          }
        }
        .social-link {
          display: flex;
          flex-direction: column;
          gap: 10px;
          .link-container {
            font-weight: bold;
            .link {
              margin-left: 10px;
              text-decoration: underline;
              color: #c57d16;
            }
          }
        }
      }
      }
    }
  
`;

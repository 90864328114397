import React ,{ useEffect} from "react";
import Button from '@mui/material/Button';
import { useNavigate } from "react-router-dom";
import { useDispatch } from 'react-redux';
import { headerText } from '../../actions/actions'
import { MainBox, WrapperIcon } from "./MeetTheCharacters.style";
import orangeImg from "../../Assets/Images/MeetTheCharacters/orange-girl.png";
import brownImg from "../../Assets/Images/MeetTheCharacters/brown-girl.png";
import greenImg from "../../Assets/Images/MeetTheCharacters/green-girl.png";
import blueImg from "../../Assets/Images/MeetTheCharacters/blue-girl.png";


function MeetTheCharacters() {

  let navigate = useNavigate();
    async function handleSubmit() {
        navigate('/');
    }
    const dispatch = useDispatch()

    useEffect(() => {
      dispatch(headerText('MEET_CHARACTERS'))
  },[dispatch])

  return (
    <>
      <MainBox>
        <div className="box">
          <div className="parent-head">
          <Button onClick={handleSubmit}><WrapperIcon fontSize="large"/></Button>
          <div className="parent-div">
          <div className="meet-the-characters">
            <span>Meet the Characters</span>
          </div>
          </div>
          </div>
          <div className="wrapper-content">
            <div className="parent-first-div">
              {/* <div className="blue-girl-image"></div> */}
              <div>
                <img src={blueImg} className="blue-girl-image" alt="idol"></img>
              </div>
              <div className="blue-text">
                <span>
                  Hi, I’m Kayden Denise, I’m City’s cousin <br /> and I like
                  scripting and games. When I <br /> grow up I want to be a
                  movie director.
                </span>
              </div>
            </div>
            <div className="parent-second-div">
              {/* <div className="brown-girl-image"></div> */}
              <div>
                <img src={brownImg} className="brown-girl-image" alt="idol"></img>
              </div>
              <div className="brown-text">
                <span>
                  Hello, I’m City Garland, I’m 8 years <br /> old. I live with
                  my parents in New York <br /> City. I enjoy drawing and
                  designing. My <br /> dream is to become a Fashion Designer{" "}
                  <br /> and I am working towards making that <br />
                  dream come true. Join me on this jour-
                  <br /> ney
                </span>
              </div>
            </div>
            <div className="parent-third-div">
              {/* <div className="orange-girl-image"></div> */}
              <div>
                <img src={orangeImg} className="orange-girl-image" alt="idol"></img>
              </div>
              <div className="orange-text">
                <span>
                  Hello, I'm Riley, I am also one of City's <br /> cousins and I
                  enjoy playing the piano,
                  <br /> swimming and speaking Mandarin.I want to <br /> be a
                  fashion star when I grow up.
                </span>
              </div>
            </div>
            <div className="parent-fourth-div">
              {/* <div className="green-girl-image"></div> */}
              <div>
                <img src={greenImg} className="green-girl-image" alt="idol"></img>
              </div>
              <div className="green-text">
                <span>
                  Hey, I'm Bailey, City's cousin. I like drawing and <br/> dancing.I
                  want to be a teacher when I grow up so <br/> that I can inspire
                  children like myself.
                </span>
              </div>
            </div>
          </div>
        </div>
      </MainBox>
    </>
  );
}

export default MeetTheCharacters;

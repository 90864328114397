import React, { useRef } from "react";
import { Box, style } from "@mui/system";
import DownloadIcon from "@mui/icons-material/Download";
import PrintIcon from "@mui/icons-material/Print";
import { saveAs } from 'file-saver';
import printJS from "print-js"
import firstImg from "../../Assets/Images/DesignsPage/first.jpg";
import thirdImg from "../../Assets/Images/DesignsPage/second.jpg";
import secondImg from "../../Assets/Images/DesignsPage/third.jpg";
import fourthImg from "../../Assets/Images/DesignsPage/fourth.jpg";
import fifthImg from "../../Assets/Images/DesignsPage/fifth-girl.jpg";
import sixthImg from "../../Assets/Images/DesignsPage/sixth-girl.jpg";


function ImageDesign({image, props}){

    const downloadImage = (imageUrl) => {
        
        saveAs(props.printImage, 'image') // Put your image url here.
      }

    
    return(
        <>
        <Box
              className={props.boxClassName}
            > 
            <div className="wrapper-image">
              <img
               
                src={image}
                className={props.imageClassName}
                alt="designs"
              />
              
              <div className="icon-div">
                  <div className="parent-icon-div" style={{marginRight:"6px"}} >
                <DownloadIcon onClick={() => downloadImage(image)} style={{color:"#c57d16"}}  />
                </div>
                <div className="parent-icon-div"  >
                <PrintIcon onClick={() => printJS(props.printImage, 'image')} style={{color:"#c57d16"}} />
                </div>
              </div>
              </div>
             
              </Box>
        </>
    );
}

export default ImageDesign;
import styled from "styled-components";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";

import { responsive } from "Styles/Constants";

export const MainBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  .span-test {
    height: 100px;
    background-color: gold;
  }
  .div-test {
    background-color: red;
    width: 20px;
    height: 20px;
    &:hover {
      + .span-test {
        background-color: black;
      }
    }
  }
  .div-test:hover + .span-test {
    background-color: pink;
  }

  .box {
    box-shadow: 0px 10px 0 0 rgba(0, 0, 0, 0.09);
    border: solid 10px #c57d16;
    min-width: 70vw;
    display: flex;
    flex-direction: column;
    margin-bottom: 46px;

    ${responsive.TABLET`
           min-width:98vw;
        
        `}
  }

  .parent-head {
    display: flex;
    padding-top: 14px;
    padding-bottom: 9px;
  }

  .parent-div {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
  }

  .designs {
    font-family: InkFree;
    font-size: 27px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    text-align: left;
    color: #c47c15;
  }

  .parent-images {
    display: flex;
    justify-content: space-evenly;
    height: 300px;
    padding-top: 25px;
    padding-bottom: 25px;
  }

  .parent-first-container {
    display: flex;
    flex-direction: column;
  }

  .first-img {
    height: 180px;
    width: 125px;
    border: solid 3px #9d5425;
    ${responsive.LAPTOP`
            height:153px;
        `}
  }

  .parent-second-container {
    display: flex;
    flex-direction: column;
    margin-top: auto;
  }

  .second-img {
    height: 180px;
    width: 125px;
    border: solid 3px #9d5425;
    ${responsive.LAPTOP`
            height:153px;
        `}
  }

  .parent-third-container {
    display: flex;
    flex-direction: column;
  }

  .third-img {
    height: 180px;

    ${responsive.LAPTOP`
            height:153px;
        `}
  }

  .parent-fourth-container {
    display: flex;
    flex-direction: column;
    margin-top: auto;
  }

  .fourth-img {
    height: 180px;
    ${responsive.LAPTOP`
            height:153px;
        `}
  }

  .parent-fifth-container {
    display: flex;
    flex-direction: column;
  }

  .fifth-img {
    height: 180px;
    ${responsive.LAPTOP`
            height:153px;
        `}
  }

  .parent-sixth-container {
    display: flex;
    flex-direction: column;
    margin-top: auto;
  }

  .sixth-img {
    height: 180px;
    width: 125px;
    border: solid 3px #9d5425;
    ${responsive.LAPTOP`
            height:153px;
        `}
  }

  .overlay {
    opacity: 0;
    top: 0;
    height: 100%;
    width: 100%;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 2;
    cursor: pointer;
  }

  .wrapper-image {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  .wrapper-image:hover .icon-div {
    display: flex;
  }

  .icon-div {
    display: none;
    position: absolute;
    height: 100%;
    width: 100%;
    justify-content: center;
    align-items: center;
    background-color: #938a8aab;
    transition: all 0.7s ease-in-out;

  }

  .parent-icon-div {
    border-radius: 5px;
    padding: 3px;
    background-color: white;
    display: flex;
    justify-content: space-between;
    cursor: pointer;
  }
`;

export const WrapperIcon = styled(ChevronLeftIcon)`
  color: #c47c15;
  align-self: center;
  margin-left: 25px;
`;

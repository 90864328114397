import styledComponents from "styled-components";
import scooterGirlImg from "../../../Assets/Images/TopBar/Scooter-girl.png";
import booksImg from "../../../Assets/Images/TopBar/books-img.png";
import heartImg from "../../../Assets/Images/TopBar/heart-img.png";
import {FONTS} from '../../../Styles/Constants'
import { responsive } from "../../../Styles/Constants";

export const MainDiv = styledComponents.div`
    display: flex;
    justify-content:flex-start;
    padding-left:2%;
    padding-top:2%;
    margin-bottom:30px;
    margin-top:-12px;
    
    width: 100%;

    @media screen and (min-width: 2560px){
        margin-top:15px;
    }

    .girl-img{
        content: url(${scooterGirlImg});
         height:218px;

         @media screen and (min-width: 2560px){
            height:337px;
        }
        

        ${responsive.TABLET`
            height:203px;
            padding-top:10px;
        `}
        
    }

    
   

`;

export const WrapperDiv = styledComponents.div`
    display: flex;
    justify-content:center;
    flex-direction:column;
    // width:38%;
    width: 100%;
    height:217px;


    .parent-author{
        display: flex;
        justify-content:space-between;
        // width:205%;
        width: 89%;
        padding-left:2%;

        @media screen and (min-width: 2560px){
            padding-top:117px;
        }

        @media screen and (max-width: 768px){
            margin-left:2%;  
        }
    }
    .text{
       
        font-family: ${FONTS.PRIMARY};
        font-size: 15px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        text-align: left;
        color: #c47c15;
        padding-top:20px;

        @media screen and (min-width: 2560px){
            font-size:30px;
        }

    }

    .parent-div{
        display:flex;
        justify-content:center;
        flex-direction:column;

        @media screen and (max-width: 768px){
            width:70%;
            justify-content:normal;
        }
    }

    .parent-right-content{
        display: flex;
        gap:12px;

        ${responsive.TABLET`
            gap:22px;
        `}

    }

    .author-img{
        height:88px;
        width:77px;
        padding-top:15px;
    }

    .logo-fashions-and-me{
        display:flex;
        justify-content:center;
        align-items:flex-end;
    }

    .heart-img{
        content:url(${heartImg});
        width:7%;
    }

    .logo-fashions-and-me-text{
        background-image: linear-gradient(to top, #c28215, #f2b65c), linear-gradient(to bottom, #e9a037, #e9a037);
        font-family: ${FONTS.PRIMARY};
        font-size: 27px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        text-align: left;
        color: #e9a037;
        -webkit-background-clip: text;
        background-clip: text;
        -webkit-text-fill-color: transparent;

        @media screen and (min-width: 2560px){
            font-size:42px;
        }

        ${responsive.TABLET`
            font-size:15px;
        `}
    }

    .author-text{
        
        background-image: linear-gradient(to top, #c28215, #f2b65c), linear-gradient(to bottom, #e9a037, #e9a037);
        font-family: ${FONTS.PRIMARY};
        font-size: 19px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        text-align: left;
        color: #e9a037;
        -webkit-background-clip: text;
        background-clip: text;
        -webkit-text-fill-color: transparent;
        display: flex;
        justify-content: flex-end;
        // border: 2px solid;

        @media screen and (min-width: 2560px){
            font-size:42px;
        }

        ${responsive.TABLET`
            font-size:15px;
        `}
    }

    .book-series-by-author{
        @media screen and (max-width: 834px){
            width: 300px;
        }

        @media screen and (max-width: 768px){
             width:310px;
        }
    }

    .parent-book-series{
        display:flex:
        justify-content:flex-end;
        

    }

    .books-img{
        // background-image: url(${booksImg});
        // width:207%;
        // height:59%;
        // background-size:contain;
        // background-repeat:no-repeat;
        width:76vw;
        margin-top:27px;
        
        ${responsive.TABLET`
            width:100%;
        `}
       
        

    }
`;
import React from "react";
import { Footer } from "../Footer/Footer.style";
import KeyboardDoubleArrowDownIcon from '@mui/icons-material/KeyboardDoubleArrowDown';

function FootBar() {
  return (
    <>
      <Footer>
        <div className="gif">
        <KeyboardDoubleArrowDownIcon className="infinite-download"></KeyboardDoubleArrowDownIcon>
        </div>
        <div className="purchase-link">
            <span className="own-portal-link">
                Available for Purchase : <a className="link"  target="_blank" rel="noreferrer" href="https://store.bookbaby.com/book/sneak-peek">BookBaby</a>
            </span>

            <span className="outer-portal-link">
                <span className="text-style">| <a className="link"  target="_blank" href = " https://www.amazon.com/s?k=isbn+9781667846071">Amazon</a> |</span><a className="link"  target="_blank" rel="noreferrer" href = "https://www.barnesandnoble.com/w/sneak-peek-luisa-mclaurin/1141500985?ean=9781667846071">Barnes & Noble</a>
            </span>
        </div>
      </Footer>
    </>
  );
}

export default FootBar;

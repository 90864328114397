import styled from "styled-components";
import {
    FONTS,
    responsive
} from 'Styles/Constants.js'


export const Footer = styled.div `
    text-align: center;
    ${responsive.TABLET`]
        padding: 0rem 3rem;
    `}
    .purchase-link{
        padding-bottom: 1rem;
        .own-portal-link{
            font-family: ${FONTS.PRIMARY};
            font-size: 24px;
            font-weight: bold;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            text-align: center;
            color: #c47c15;
            ${responsive.TABLET`
            font-size:18px;
        `}
            .link{
                text-decoration: underline;
                color: #c47c15;
            }
        }
        .outer-portal-link{
            margin: 0px 10px;
            font-family: ${FONTS.PRIMARY};
            font-size: 24px;
            font-weight: bold;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: -0.96px;
            text-align: left;
            color: #c47c15;
            .text-style{
                margin-right: 10px;
                letter-spacing: normal;
            }
            .link{
                text-decoration: underline;
                color: #c47c15;

                ${responsive.TABLET`
            font-size:18px;
        `}

            }
        }
    }
    .infinite-download{
        color: red;
        animation: name-of-animation 800ms infinite;
        height: 45px;
        width:80px;
        
    }

    @keyframes name-of-animation {
        0%    { transform: translateY(0); }
        25%  { transform: translateY(5%); }
        50%  { transform: translateY(10%); }
        75%  { transform: translateY(5%); }
        100%    { transform: translateY(0); }
    }

.animate-this-element {
  animation: name-of-animation 5s infinite;
}
`;
import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom'
import { Route, Routes } from "react-router-dom";
import { Navigate } from "react-router-dom";
import { WebsiteWrapper } from './Website.style';
import TopBar from '../Components/Commons/TopBar/TopBar';
import LandingPage from './Home/homepage';
import Biography from './Biography/biography'
import ComingSoon from './ComingSoon/ComingSoon';
import FootBar from '../Components/Commons/Footer/Footer';
import MeetTheCharacters from './MeetTheCharacters/MeetTheCharacters';
import ContactUs from './ContactUs/ContactUs';
import Designs from './Designs/Designs';


const Website = props => {
    return (
        <WebsiteWrapper>
            <Router>
            <TopBar />
                <Routes>
                    <Route path="/" element={<LandingPage />}>
                    </Route>
                    <Route path="/biography" element={<Biography />}>
                    </Route>
                    <Route path="/coming-soon" element={<ComingSoon />}>
                    </Route>
                    <Route path="/meet-characters" element={<MeetTheCharacters />}>
                    </Route>
                    <Route path="/contact-us" element={<ContactUs />}>
                    </Route>
                    <Route path="/designs" element={<Designs />}>
                    </Route>
                    <Route path='*' element={<Navigate to="/" replace/>} />
                </Routes>
            </Router>
            <FootBar />
        </WebsiteWrapper>
    );
};
export default Website;
import { createGlobalStyle } from "styled-components";
import { FONTS } from "./Constants";
import InkFreeFonts from '../Assets/Fonts/ink-free.ttf'

export const UtilityStyles = createGlobalStyle`
    * {
        box-sizing  : border-box;
        outline     : none;
    }
    html,
    body {
        margin: 0;
        padding: 0;
        background-color: white;
        /* overflow: hidden; */
        font-family         : ${FONTS.PRIMARY};
        line-height         : 1.2;
        min-height          : 100vh;
        position            : relative;
    }

    #root {
        display: flex;
        flex-direction: column;
    }
    .full-height {
        height: 100%;
    }

    .full-width {
        width: 100%;
    }

    .flex {
        display: flex;
    }

    .f-column {
        flex-direction: column;
    }

    .f-v-center { 
        align-items: center;
    }

    .f-h-center {
        justify-content: center;
    }

    .f-h-space-between {
        justify-content: space-between;
    }

    .text-center {
        text-align: center;
    }

    //FONT SIZES

    p {
    }

    h2 {
        margin: 0
    }

    @font-face {
        font-family: ${FONTS.PRIMARY};
        font-style: normal;
        font-weight: normal;
        src: url(${InkFreeFonts}) format('truetype');
    }
`
import React,{useEffect} from "react";
import Button from '@mui/material/Button';
import { useNavigate } from "react-router-dom";
import { ComingSoonWrapper, WrapperIcon } from "./ComingSoon.style";
import BookImage from 'Assets/Images/LandingPage/bookpage.webp';
import { useDispatch } from 'react-redux';
import { headerText } from '../../actions/actions'

function ComingSoon() {
    let navigate = useNavigate();
    async function handleSubmit() {
        navigate(-1);
    }
    const dispatch = useDispatch()
    useEffect(() => {
        dispatch(headerText('COMING_SOON'))
    },[dispatch])

    return (
        <>
            <ComingSoonWrapper>
                <div className="container">
                    <div className="content-container">
                        <div className="heading">
                        <span>
                        <Button className="btn" onClick={handleSubmit}><WrapperIcon /></Button>
                    </span>
                            <span className="top-heading">Coming Soon</span>
                        </div>
                        <div className="image-container">
                            <img class="book-image" src={BookImage} alt='book' />
                            <div class="empty-image">
                                <div className="text">Book II</div>
                                <div className="text">Coming Soon</div>
                            </div>
                        </div>
                    </div>
                </div>
            </ComingSoonWrapper>
        </>
    );
}

export default ComingSoon;

import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { MainDiv, WrapperDiv } from "../TopBar/TopBar.style";
import booksImg from "../../../Assets/Images/TopBar/books-img.png";
import auhtorImg from "../../../Assets/Images/TopBar/author-book.png";
function TopBar() {
  let navigate = useNavigate();
  async function handleClick() {
    navigate(-1);
  }

  const topBarText = useSelector((state) => state.pages.HEADER_TEXT);
  const test = useSelector((state) => state.pages);

  const [displayImage, setDisplayImage] = useState(false);

  useEffect(() => {
    if (test.displayLogo) setDisplayImage(true);
    else setDisplayImage(false);
  }, [test]);

  return (
    <>
      <MainDiv>
        <div onClick={handleClick} className="girl-img">
          {" "}
        </div>
        <WrapperDiv>
          <div className="parent-author">
            <div className="text">
              <div
                dangerouslySetInnerHTML={{
                  __html: topBarText,
                }}
              />
            </div>
            <div className="parent-right-content">
        
            <div className="parent-div">
              <div className="logo-fashions-and-me">
                <div className="heart-img"></div>
                <div className="logo-fashions-and-me-text">
                  {" "}
                  <span> Fashions And Me </span>{" "}
                </div>
              </div>
              

              {/* <div className="author-text"> */}
              {/* <div className="parent-book-series"> */}
              <span
                style={{
                  color: "#e9a037",
                  fontWeight: "bold",
                  textAlign: "right",
                }}
                className="book-series-by-author"
              >
                Book series by author, Luisa McLaurin!
              </span>
              
              {/* </div> */}
              {/* </div> */}
            </div>
            {displayImage && (
                <div >
                  <img src={auhtorImg} className="author-img" />
                </div>
              )}
            </div>
          </div>

          {/* <div className="books-img"></div> */}
          <div>
            <img src={booksImg} className="books-img"></img>
          </div>
        </WrapperDiv>
      </MainDiv>
    </>
  );
}

export default TopBar;

import styled from "styled-components";
import { TextField } from "@mui/material";
import { Button } from "@mui/material";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import { responsive } from "Styles/Constants";

export const MainBox = styled.div`
    display:flex;
    justify-content:center;
    align-items:center;
    flex-direction:column;

    .box{
        box-shadow: 0px 10px 0 0 rgba(0, 0, 0, 0.09);
        border: solid 10px #c57d16;
        min-width:70vw;
        display:flex;
        flex-direction:column;
        margin-bottom: 5%;
    }

    .contact-us{
        display:flex;
        justify-content:center;
        font-family: InkFree;
        font-size: 27px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        text-align: left;
        color: #c47c15;

        ${responsive.LAPTOP`
            font-size:23px;
        `}

        ${responsive.TABLET`
            font-size:20px;
        `}
    }

    .parent-head{
        display:flex;
        padding-top: 14px;
        padding-bottom: 9px;
    }

    .parent-div{
        display:flex;
        justify-content:center;
        align-items:center;
        width: 89%;

        ${responsive.LAPTOP`
            width:83%;
        `}

        ${responsive.TABLET`
            width:73%;
        `}
    }

    .form{
        display: flex;
        flex-direction:column;
        justify-content: center;
        gap: 16px;
        align-items: center;
    }
    
`;

export const WrapperIcon = styled(ChevronLeftIcon)`
    color: #c47c15;
    align-self:center;
    margin-left: 25px;
`;

export const WrapperName = styled(TextField)`

        width:53vw;
        font-weight: bold;
        color: #c47c15;
        border-bottom-color:#c47c15;
        
    input{
        color: #8e5809 !important;
        font-weight: bold;
    }

    && :before{
        border-bottom: 1px solid #c47c15;
    }

    .MuiOutlinedInput-root{
        font-family: InkFree;
        font-size: 16px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        text-align: left;
        color: #c47c15;
    }

    ::placeholder{
        font-weight: bold !important;
    }
`;

export const WrapperEmail = styled(TextField)`
    
        width:53vw;
        color: #c47c15;
        border-bottom-color:#c47c15;
        border-bottom: none;
    
        
    && :before{
        border-bottom: 1px solid #c47c15;
    }
    && :hover{
            border-bottom-color: 2px solid #c47c15;
        }
    
    input{
        color: #8e5809 !important;
        font-weight: bold;
    }

        
    .MuiOutlinedInput-root, .MuiSelect-nativeInput{
        font-family: InkFree;
        font-size: 16px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        text-align: left;
        color: #c47c15;
    }
`;

export const WrapperMessage = styled(TextField)`
    /* border: 2px solid #c47c15 !important; */
    width:53vw;
    height:15vh !important;
    color:#c47c15;
    background-color: transparent;
    font-weight: bold;
    font-family: InkFree;

    ::placeholder {
         color: #c47c15  ;
         font-family: InkFree;
         font-size: 16px;
         font-weight: bold;
         font-stretch: normal;
         font-style: normal;
         line-height: normal;
         text-align: left;
       }
    
    .MuiOutlinedInput-notchedOutline {
        border: none;
        border-bottom: 2px solid #c47c15;
    }

    .MuiOutlinedInput-input{
        color: #8e5809;
        font-weight: bolder;
    }

    .MuiOutlinedInput-root{
        padding-left: 0px ;
    }
   
`;

// export const WrapperMessage = styled(TextareaAutosize)`
//     border: 2px solid #c47c15 !important;
//     width:53vw;
//     height:15vh !important;
//     color:#c47c15;
//     background-color: transparent;
//     font-weight: bold;
    
//     ::placeholder {
//         color: #c47c15  ;
//         font-family: InkFree;
//         font-size: 16px;
//         font-weight: bold;
//         font-stretch: normal;
//         font-style: normal;
//         line-height: normal;
//         text-align: left;
//       }
    
// `;

export const WrapperButton = styled(Button)`
    
        font-family: InkFree !important;
        font-size: 30px !important;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        text-align: left;

        ${responsive.LAPTOP`
            font-size: 24px !important;
        `}

        ${responsive.TABLET`
            font-size: 20px  !important;
        `}
`

 
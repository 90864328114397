import { createBreakpoints } from "@mui/system";
import { BREAKPOINTS_VALUE, COLORS, DIMENTIONS } from "./Styles/Constants";

const breakpoints = createBreakpoints({
    keys: ['xs','sm', 'md' , 'lg' , 'xl'],
    values: {
        xs: 0,
        sm: BREAKPOINTS_VALUE.PHABLET,
        md: BREAKPOINTS_VALUE.TABLET,
        lg: BREAKPOINTS_VALUE.LAPTOP,
        xl: BREAKPOINTS_VALUE.DESKTOP
    }
});

export const THEME_SETTINGS = {
	palette: {
		primary: {
			light	: COLORS.PRIMARY_LIGHT,
			main	: COLORS.PRIMARY,
			dark	: COLORS.PRIMARY_DARK,
		},
		secondary: {
			light	: COLORS.SECONDARY_LIGHT,
			main	: COLORS.SECONDARY,
			dark	: COLORS.SECONDARY_DARK,
		},
	},
	breakpoints,
	overrides: {
		MuiAppBar: {
			root: {
				height : `${DIMENTIONS.TOPBAR}px`,
			}
		},
		MuiButton: {
			contained: {
				color : 'white',
			},
			containedPrimary: {
				color : 'white',
			},
			containedSecondary: {
				color : 'white',
			},
			outlinedPrimary: {
				borderRadius	: '25px',
				border			: '1px solid #000000',
				color			: '#000000',
			},
			sizeLarge: {
				fontSize: '16px'
			}
		},
	},
	// typography: {
	// 	useNextVariants	: true,
	// 	fontFamily		: "Gotham Book",
	// }
}



import React, { useEffect } from "react";
import Button from "@mui/material/Button";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { headerText } from "../../actions/actions";
import { MainBox, WrapperIcon } from "./Designs.style";
import firstImg from "../../Assets/Images/DesignsPage/first-girl.png";
import firstPrintImg from "../../Assets/Images/DesignsPage/first.jpg";
import thirdPrintImg from "../../Assets/Images/DesignsPage/third.jpg";
import secondPrintImg from "../../Assets/Images/DesignsPage/second.jpg";
import thirdImg from "../../Assets/Images/DesignsPage/second-girl.png";
import secondImg from "../../Assets/Images/DesignsPage/third-girl.png";
import fourthImg from "../../Assets/Images/DesignsPage/fourth.jpg";
import fifthImg from "../../Assets/Images/DesignsPage/fifth-girl.jpg";
import sixthImg from "../../Assets/Images/DesignsPage/sixth-girl.jpg";
import Baggirl from 'Assets/Images/DesignsPage/BagGirl.jpg'
import ShoppingGirl from 'Assets/Images/DesignsPage/ShoppingGirl.jpg'
import ShoppingGirl2 from 'Assets/Images/DesignsPage/ShoppingGirl2.jpg'

import ListItemIcon from "@mui/material/ListItemIcon";
import MenuList from "@mui/material/MenuList";
import MenuItem from "@mui/material/MenuItem";
import Popover from "@mui/material/Popover";
import { Box} from "@mui/material";
import { saveAs } from 'file-saver';
import CustomMenu from "./ImageDesign";
import ImageDesign from "./ImageDesign";

function Designs() {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [test, setTest] = React.useState([null, null, null, null, null, null]);
  function handleClick(event, num) {
    setAnchorEl(event.currentTarget);
    console.log(test.map((item, index) => {
      if (index === num) return event.currentTarget;
      else return item;
    }));
    setTest(
      test.map((item, index) => {
        if (index === num) return event.currentTarget;
        else return item;
      })
    );
  }

  function handleClose(e, num) {
    console.log(e);
    setAnchorEl(null);
    setTest(
      test.map((item, index) => {
        if (index === num) return null;
        else return item;
      })
    );
  }
  let navigate = useNavigate();
  async function handleSubmit() {
    navigate(-1);
  }

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(headerText("DESIGNS"));
  }, [dispatch]);

  

  return (
    <>
      <MainBox>
  
        <div className="box">
          <div className="parent-head">
            <Button onClick={handleSubmit}>
              <WrapperIcon />
            </Button>
            <div className="parent-div">
              <div className="designs">
                <span>Designs</span>
              </div>
            </div>
          </div>
          <div className="parent-images">
            
            <ImageDesign image={ShoppingGirl2} props={{boxClassName:"parent-first-container", imageClassName:"first-img", printImage:ShoppingGirl2}}  />
          
            <ImageDesign image={ShoppingGirl} props={{boxClassName:"parent-second-container", imageClassName:"second-img", printImage:ShoppingGirl}}  />
           
            <ImageDesign image={thirdImg} props={{boxClassName:"parent-third-container", imageClassName:"third-img", printImage:thirdPrintImg}}  />
           
            <ImageDesign image={firstImg} props={{boxClassName:"parent-fourth-container", imageClassName:"fourth-img", printImage:firstPrintImg}}  />
           
            <ImageDesign image={secondImg} props={{boxClassName:"parent-fifth-container", imageClassName:"fifth-img", printImage:secondPrintImg}}  />
            
            <ImageDesign image={Baggirl} props={{boxClassName:"parent-sixth-container", imageClassName:"sixth-img", printImage:Baggirl}}  />
           
          </div>
        </div>
      </MainBox>
    </>
  );
}

export default Designs;

//Baggirl
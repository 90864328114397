import styled from 'styled-components';
import { Box } from '@mui/material';

import homeWrapperImg from "Assets/Images/BackgroundImage/background-image.webp";

export const WebsiteWrapper = styled(Box)`
    /* background-image:url(${homeWrapperImg}); */
    background-image: linear-gradient(to bottom, #f8f8f8, #fac6e5 55%, #f0cdb5, #fffffe);
    width: 100vw;
    height: 100vh;
    min-height: 100vh;
    overflow-x: hidden;
`;
import React, { useEffect } from "react";
import Button from "@mui/material/Button";
import { useNavigate } from "react-router-dom";
import { BiographyWrapper, WrapperIcon } from "./biography.style";
import { useDispatch } from "react-redux";
import { headerText } from "../../actions/actions";
import auhtorImg from "../../Assets/Images/TopBar/author-book.png";

function Biography() {
  const dispatch = useDispatch();
  let navigate = useNavigate();
  async function handleSubmit() {
    navigate(-1);
  }
  useEffect(() => {
    dispatch(headerText("BIOGRAPHY"));
  }, [dispatch]);

  return (
    <>
      <BiographyWrapper>
        <div className="container">
          <div>
            <div className="heading">
              <span>
                <Button className="btn" onClick={handleSubmit}>
                  <WrapperIcon />
                </Button>
              </span>
              <span className="top-heading">Biography</span>
            </div>
            <div className="mini-container">
              <div className="first-container">
                <div className="parent-head">
                  <div className="heading">Author</div>
                  <div>
                    <img src={auhtorImg} className="author-img" />
                  </div>
                </div>
                <div>
                  <p className="text">
                    <span className="addressing"> Hello my readers!</span>
                    <div className="content">
                      <span className="addressing">I’m </span>
                      <span className="author-name">Luisa</span>
                      <span className="separator">,</span> I was Born in Carson,
                      MS, now living in Las Vegas NV. I am an inspiring Guest
                      Teacher. When I’m not teaching, I am working on the next
                      book for City Garland’s journey. I was inspired by the
                      little ones that I teach, because of their inquisitive
                      minds and how they view life, which led me to write this
                      story, a story that will inspire all children to follow
                      their dreams.
                    </div>
                  </p>
                </div>
              </div>
              <div className="second-container">
                
                  <div className="heading">Illustrator</div>
                  <div className="parent-content">
                  <div className="description">
                    <p className="text">
                      <span className="author-name">Ha Meo</span> is a young
                      mother and Children’s book illustrator who puts her heart
                      into all of her art. Her character drawings convey
                      emotions and her choice of color captures a fairytale like
                      world of dreams.
                    </p>
                  </div>
                  <div className="social-link">
                    <div className="link-container">
                      {" "}
                      Website:{" "}
                      <a
                        className="link"
                        rel="noreferrer"
                        target="_blank"
                        href="https://hameoart.com/"
                      >
                        www.hameoart.com
                      </a>{" "}
                    </div>
                    <div className="link-container">
                      {" "}
                      Email:{" "}
                      <a
                        className="link"
                        rel="noreferrer"
                        href="mailto:hameoartstudio@gmail.com"
                      >
                        hameoartstudio@gmail.com
                      </a>{" "}
                    </div>
                    <div className="link-container">
                      {" "}
                      Instagram: <span className="link">hameoart</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </BiographyWrapper>
    </>
  );
}

export default Biography;

import styled from "styled-components";
import { responsive } from 'Styles/Constants.js';

export const MainContainerWrapper = styled.div `
    display:flex;
    flex-direction: column;
    justify-content:space-evenly;
    align-items:center;
    margin:auto;
    width:90%;
    margin-bottom: 40px;
    max-width: 1200px;
    padding-top: 2rem;
    padding-bottom: 2rem; 
    padding-left: 1rem;
    box-shadow: 0px 10px 0 0 rgba(0, 0, 0, 0.09);
    border: solid 10px #c57d16;
    height: 450px; 
    ${responsive.TABLET`
        padding-right: 1rem;
    `}
    .link-content{
        width: 100%;
        margin-left: -9%;
      
      ${responsive.TABLET`
        margin-left:-2%;
    `}

        .primary-link{
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            list-style-type: none;
            .link{
                margin: 1px 20px 40px -13px;
                font-family: InkFree;
                font-size: 24px;
                font-weight: bold;
                font-stretch: normal;
                font-style: normal;
                line-height: normal;
                letter-spacing: -1.36px;
                text-align: left;
                text-decoration: underline;
                color: #c47c15;
                ${responsive.TABLET`
                    font-size: 22px;
                `}
            }
        }
    }
    .image-container{
        margin-top: -40px;
        display: flex;
        flex-direction: row;
        gap:60px;
        height: 100%;
        ${responsive.TABLET`
            
        `}
        @media only screen and (max-width:834px){
            gap:15%;
        }
        .first-block{
            display: flex;
            flex-direction:column;
            width: fit-content;
            height: 90%;
            margin: auto;
            .book-image{
                width: 70%;
                z-index: 1;
                object-fit: contain;
                
                @media only screen and (max-width:834px){
                    width: 100%;
                }
            }
            .empty-image{
                width: 68%;
                height: 55%;
                margin: -110px 0px 0px 58px;
                border: solid 3px #9d5425;
                background-color: #ecc6ad;
                display: flex;
                flex-direction: column;
                justify-content: flex-end;
                padding-bottom: 5px;
                ${responsive.TABLET`
                    margin: -80px 0px 0px 58px;
                    padding-bottom: 2px;
                `}
                 @media only screen and (max-width:834px){
                    width: 100%;
                }
                .text{
                    font-size: 18px;
                    //margin: 135px 0px -137px 0px;
                    font-weight: normal;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: normal;
                    letter-spacing: normal;
                    text-align: center;
                    color: #9d5425;
                    ${responsive.TABLET`
                         font-size: 14px;
                    `}
                }
            }
        }
        .second-block{
            height: 90%;
            display: flex;
            gap: 8%;
            .book-image{
                width: 50%;
                height: 55%;
                margin: 45px 0px 0px 10px;
                ${responsive.TABLET`
                    width: 65%;
                    height: 55%;
                    margin: 45px 0px 0px -15px;
                `}
                 @media only screen and (max-width:834px){
                    width: 100%;
                }
            }
            .girl-image{
                width: 50%;
                height: 55%;
                margin-top: auto;
                ${responsive.TABLET`
                    width: 65%;
                    height: 55%;
                `}
                 @media only screen and (max-width:834px){
                    width: 100%;
                }
            }
        }
        .third-block{
            display: flex;
            flex-direction: column;
            height: 90%;
            gap: 10px;
            margin: auto 0px auto 90px;
            ${responsive.TABLET`
                margin: auto 0px auto 30px;
            `}
            .book-image{
                height: 48%;
                ${responsive.TABLET`
                    width: 100%;
                    height: 60%;
                `}
                @media only screen and (max-width:834px){
                    width: 130%;
                    height: 60%;
                }
                @media only screen and (min-width:850px) and (max-width:960px){
                    width: 100%;
                    height: 45%;
                }
                
            }
            .girl-image{
                height: 50%;
                ${responsive.TABLET`
                    width: 100%;
                    height: 60%;
                `}
                 @media only screen and (max-width:834px){
                    width: 130%;
                    height: 60%;
                }
                @media only screen and (min-width:860px) and (max-width:960px){
                    width: 100%;
                    height: 45%;
                }
            }
        }
        .fourth-block{
            margin-top: 90px;
            height: 90%;
            .dance-image{
                height: 50%;
                ${responsive.TABLET`
                    height: 48%;
                    margin-left: -54px
                `}
                @media only screen and (max-width:768px){
                    width: 360%;
                    height: 50%;
                }
                 @media only screen and (max-width:834px){
                    width: 360%;
                    height: 50%;
                }
                @media only screen and (min-width:850px) and (max-width:960px){
                    width: 230%;
                    height: 45%;
                }
            }
        }
    }
    
    
`;